// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  API_URL: "https://st-admin-backend.sportstiger.co/v2/", //test server

  WEBSITE_URL: "https://sportstiger.co",

  ImageBaseUrl: "https://sports-365.s3.amazonaws.com/",

  BASE_URL: "https://sportstiger.co/",

  LOAD_STATIC_URL: "www.sportstiger.co",

  HINDI_WEBSITE_URL: "https://hindi.sportstiger.com",

  MEDIA_URL: "https://media.sportstiger.com/",
};
